<template lang="pug">
  .timer {{ value }}
</template>

<script>
  import { ja } from "date-fns/locale"
  import { formatDistance } from "date-fns"
  import withLocale from "@/mixins/withLocale"

  export default {
    mixins: [withLocale],

    props: {
      start: {
        type: Date,
        default: new Date()
      }
    },

    data() {
      return {
        value: "",
        timer: undefined
      }
    },

    mounted() {
      this.setValue()

      this.timer = setInterval(this.setValue, 1000)
    },

    beforeDestroy() {
      clearInterval(this.timer)
    },

    methods: {
      setValue() {
        this.value = formatDistance(
          new Date(),
          new Date(this.start),
          { locale: this.currentLocale === "ja" ? ja : null } // Pass the locale as an option
        )
      }
    }
  }
</script>
